import React, { useState } from 'react'
import { Descriptions, Pagination } from 'antd'
import styles from './Details.module.css'

const Details = ({ data }) => {
 const [currentPage, setCurrentPage] = useState(1);

 const pageSize = 1; // show one object per page
 const totalObjects = data.rows.length;
 const totalPages = Math.ceil(totalObjects / pageSize);

 const handlePageChange = (page) => {
  setCurrentPage(page);
 };

 const currentPageObjects = data.rows.slice(
  (currentPage - 1) * pageSize,
  currentPage * pageSize
 );

 return (
  <div className={styles.detailsViz}>
   <Descriptions
    bordered
    labelStyle={{ width: '20%' }}
    contentStyle={{ width: "80%" }}
   >
    {currentPageObjects.map((object, index) =>
     data.columns.map((column) => (
      <Descriptions.Item key={column.name} label={column.friendly_name || column.name} span={data.columns.length}>
       {object[column.name] || "undefined"}
      </Descriptions.Item>
     ))
    )}
   </Descriptions>

   <Pagination
    current={currentPage}
    pageSize={pageSize}
    total={totalObjects}
    onChange={handlePageChange}
   />
  </div>
 )
}

export default Details