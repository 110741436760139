import React from "react";
import { Table as AntTable, Input } from "antd";
import moment from "moment"
import { updateURLTable } from "./Vis.utils";
const numeral = require('numeral');

function Table({ data, visualization }) {
    const { columns: metadata, itemsPerPage = 10 } = visualization?.options
    const searchCols = metadata?.filter(col => col.allowSearch).map(col => col.title) || []
    const [searchText, setSearchText] = React.useState("")

    const formatValue = (value, formatString) => {
        const style = formatString ? formatString?.includes('%') ? 'percent' : 'decimal' : 'decimal';
        const formatter = new Intl.NumberFormat('en-US', {
            style,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
            useGrouping: true,
        });
        return formatter.format(value);
    };

    const columns = React.useMemo(() => {
        return metadata ? (
            metadata?.filter(col => col.visible).map(col => {
                return {
                    title: col.title,
                    dataIndex: col.name,
                    key: col.order,
                    align: col.alignContent,
                    filtered: col?.allowSearch,
                    filteredValue: [searchText],
                    onFilter: (searchValue, record) => {
                        if (col?.allowSearch) {
                            const searchCols = metadata
                                .filter((col) => col.allowSearch)
                                .map((col) => col.name);
                            for (let i = 0; i < searchCols.length; i++) {
                                const cellValue = record[searchCols[i]]?.toLowerCase();
                                if (cellValue?.includes(searchValue?.toLowerCase())) {
                                    return true;
                                }
                            }
                            return false;
                        }
                        return true;
                    },
                    render: (text, record) => {
                        if (col.displayAs === 'number') {
                            return numeral(text).format(col?.numberFormat)
                        } else if (col.displayAs === 'datetime') {
                            return text ? moment(text).format(col?.dateTimeFormat) : ""
                        } else if (col.displayAs === 'boolean') {
                            return (text) ? col?.booleanValues[1] : col?.booleanValues[0]
                        } else if (col.displayAs === 'link') {
                            return (
                                <a
                                    href={updateURLTable(col?.linkUrlTemplate, text, record)}
                                    target={col?.linkOpenInNewTab ? "_blank" : "_self"}
                                    rel="noreferrer"
                                >
                                    {`${col?.linkTextTemplate.replace("{{ @ }}", text)}`}
                                </a>
                            )
                        } else {
                            return col.allowHTML ? <div dangerouslySetInnerHTML={{ __html: typeof (text) === "object" ? JSON.stringify(text) : text }} /> : typeof (text) === "object" ? JSON.stringify(text) : text;
                        }
                    },
                }
            })
        ) : (
            data.columns.map(col => {
                return {
                    title: col.friendly_name,
                    dataIndex: col.name,
                    key: col.name,
                    render: (text, record) => {
                        if (typeof (text) === "number") {
                            return formatValue(text, "0,0[.]00")
                        } else {
                            return typeof (text) === "object" ? JSON.stringify(text) : text
                        }
                    }
                }
            })
        )
    }, [data, metadata, searchText]);

    return (
        <React.Fragment>
            {
                searchCols.length > 0 && (
                    <Input.Search
                        placeholder={`Search ${searchCols.join(' and ')}...`}
                        style={{
                            width: "100%"
                        }}
                        onChange={(e) => setSearchText(e.target.value)}
                    />
                )
            }
            <AntTable
                columns={columns}
                dataSource={data.rows}
                pagination={{
                    pageSize: itemsPerPage,
                    position: [data.rows.length < 10 ? 'none' : 'bottomCenter'],
                    hideOnSinglePage: true,
                    showSizeChanger: false
                }}
            />
        </React.Fragment>
    )
}

export default Table;