import React, { useEffect, useState } from 'react'
import PivotTable from 'react-pivottable/PivotTable'
import PivotTableUI from 'react-pivottable/PivotTableUI'
import styles from "./PivotTable.module.css"

const Pivot = ({ data, options, onEdit }) => {
  const [option, setOption] = useState(options)

  useEffect(() => {
    setOption(options)
  }, [options, data])

  const onOptionChange = (value) => {
    const stateKeys = Object.keys(option)

    const updatedState = stateKeys.reduce((acc, key) => {
      if (value.hasOwnProperty(key)) {
        acc[key] = value[key];
      } else {
        acc[key] = option[key];
      }
      return acc;
    }, {})

    setOption(updatedState)
    onEdit(updatedState)
  }

  if (option?.controls?.enabled) {
    return (
      <div
        className={`
        ${!options?.rendererOptions?.table?.rowTotals ? styles.hideRowTotalsUI : ""} 
        ${!options?.rendererOptions?.table?.colTotals ? styles.hideColTotalsUI : ""} 
        `}
      >
        <PivotTableUI
          data={data.rows}
          onChange={onOptionChange}
          {...option}
        />
      </div>
    );
  } else {
    return (
      <div
        className={`
        ${!options?.rendererOptions?.table?.rowTotals ? styles.hideRowTotals : ""} 
        ${!options?.rendererOptions?.table?.colTotals ? styles.hideColTotals : ""} 
        `}
      >
        <PivotTable
          data={data.rows}
          {...option}
        />
      </div>
    );
  }
}

export default Pivot