import React from 'react'
import ReactWordcloud from 'react-wordcloud'

const WORDCLOUD_OPTIONS = {
 fontFamily: '-apple-system',
 padding: 0,
 rotations: 1,
 rotationAngles: [0, 0],
 fontSizes: [18, 30],
 scale: "ordinal",
 spiral: "rectangular",
 transitionDuration: 0,
 deterministic: true,
 enableOptimizations: true,
};
const WordCloud = ({ wordData }) => {
 return (
  <div style={{ width: "100%", height: "100%" }}>
   <ReactWordcloud
    words={wordData}
    options={WORDCLOUD_OPTIONS}
   />
  </div>
 )
}

export default WordCloud