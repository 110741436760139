import React, { useEffect, useRef, useState } from "react"
import Table from "./Table"
import { getChartOptions, getWordCloudData } from "./Vis.utils"
import VisChart, { updateURL } from "./VisChart"
import styles from '../styles.module.css'
import { Statistic } from "antd"
import PivotTable from "./PivotTable"
import Details from "./Details"
import WordCloud from "./WordCloud"
const numeral = require('numeral');

function WidgetCounter({ visualization, visData, params }) {
  const [width, setWidth] = useState(100);
  const [height, setHeight] = useState(100);
  const demoRef = useRef();

  const {
    name,
    options: { targetColName, counterColName, stringDecimal, tooltipFormat, stringPrefix, stringSuffix, linkUrlTemplate, linkOpenInNewTab, linkUrlName }
  } = visualization;

  // https://backend.idataworkers.com/Demo/queries/1019/?p_BU={{ BU_Param }}&p_Year={{ Year_Param }}#2198

  useEffect(() => {
    const resizeObserver = new ResizeObserver((event) => {
      // Depending on the layout, you may need to swap inlineSize with blockSize
      // https://developer.mozilla.org/en-US/docs/Web/API/ResizeObserverEntry/contentBoxSize
      setWidth(event[0].contentBoxSize[0].inlineSize);
      setHeight(event[0].contentBoxSize[0].blockSize);
    });

    if (demoRef) {
      resizeObserver.observe(demoRef.current);
    }
  }, [demoRef]);

  const count = () => {

    const rows = visData?.data?.rows
      .map(row => Number(row[counterColName || targetColName]))
      .filter(value => !isNaN(value));

    if (name?.toLowerCase().includes("min")) {
      return Math.min(...rows);
    } else if (name?.toLowerCase().includes("max")) {
      return Math.max(...rows);
    } else {
      return rows.reduce((acc, n) => acc + n, 0);
    }
  }


  return (
    <div ref={demoRef} className="widget-counter">
      <div className={styles.counter} title={numeral(count()).format(tooltipFormat)}>

        {
          linkUrlTemplate ? (
            linkUrlName ?
              <div
                style={{
                  textAlign: 'center'
                }}
              >
                <Statistic
                  value={`${stringPrefix || ''}${count().toFixed(stringDecimal)}${stringSuffix || ''}`}
                  valueStyle={{
                    fontSize: `${width / 10}px`,
                    color: '#0097c2',
                    fontWeight: '600'
                  }}
                />
                <a
                  href={updateURL(linkUrlTemplate, params, count)} target={linkOpenInNewTab ? "_blank" : "_self"}
                  rel="noreferrer"
                  style={{
                    fontSize: `${width / 14}px`,
                    color: '#0097c2',
                  }}
                >
                  {linkUrlName}
                </a>
              </div>
              :
              <a
                href={updateURL(linkUrlTemplate, params, count)} target={linkOpenInNewTab ? "_blank" : "_self"}
                rel="noreferrer"
                style={{
                  fontSize: `${width / 14}px`,
                  color: '#0097c2',
                }}
              >
                <Statistic
                  value={`${stringPrefix || ''}${count().toFixed(stringDecimal)}${stringSuffix || ''}`}
                  valueStyle={{
                    fontSize: `${width / 10}px`,
                    color: '#0097c2',
                    fontWeight: '600'
                  }}
                />
              </a>
          ) : (
            <Statistic
              value={`${stringPrefix || ''}${count().toFixed(stringDecimal)}${stringSuffix || ''}`}
              valueStyle={{
                fontSize: `${width / 10}px`,
                color: '#0097c2',
                fontWeight: '600'
              }}
            />
          )
        }
      </div>
    </div>
  )
}

function WidgetChart({ options, visData, params }) {
  const { data, layout } = React.useMemo(
    () => getChartOptions({ options, visData }),
    [options, visData]
  )

  return <VisChart className="widget-chart" data={data} layout={layout} params={params} />
}

function WidgetWordCloud({ data, options }) {
  const wordData = React.useMemo(
    () => getWordCloudData({ data, options }),
    [data, options]
  )


  return <WordCloud wordData={wordData} />
}

const Vis = ({ widget, data, params, onEdit }) => {
  const { type, options } = widget.visualization

  let content

  if (type === "COUNTER") {
    content = <WidgetCounter visualization={widget.visualization} visData={data} params={params} />
  } else if (type === "CHART") {
    content = (
      <WidgetChart
        options={options}
        visData={data}
        params={params}
      />
    )
  } else if (type === "TABLE") {
    content = <Table data={data.data} visualization={widget.visualization} />
  } else if (type === "PIVOT") {
    content = <PivotTable data={data.data} options={options} onEdit={onEdit} />
  } else if (type === "DETAILS") {
    content = <Details data={data.data} />
  } else if (type === "WORD_CLOUD") {
    content = <WidgetWordCloud data={data.data} options={options} />
  } else {
    content = <div className={styles.unsupportType}>Unsupported visualization type</div>
  }

  return (
    <React.Fragment>
      {content}
    </React.Fragment>
  )
}

export default Vis
