const numeral = require('numeral');

export function getChartOptions({ options, visData, widgetCoords }) {
  const { globalSeriesType: chartType } = options

  if (chartType === "pie") {
    return getPieChartOptions({ options, visData })
  } else if (chartType === "column" || chartType === "bar") {
    return getBarChartOptions({ options, visData })
  } else if (chartType === "line" || chartType === "scatter" || chartType === "area") {
    return getLineChartOptions({ options, visData })
  } else if (chartType === "bubble") {
    return getBubbleChartOptions({ options, visData })
  } else if (chartType === "waterfall") {
    return getWaterfallChartOptions({ options, visData })
  } else if (chartType === "treemap") {
    return getTreemapChartOptions({ options, visData })
  } else if (chartType === "funnel") {
    return getFunnelChartOptions({ options, visData })
  } else {
    return "Unsupported chart type"
  }
}

function getPieChartOptions({ options, visData }) {
  const { columnMapping, legend, direction: { type }, sizemode, missingValuesAsZero, hole, colors, colorPalettes } = options
  const { x, y, series } = getXAndYFromColumns(columnMapping)
  const { rows } = visData.data
  const traces = []
  const labels = rows.map((row) => row[x])

  const colorsPalettesArray = []
  if (colorPalettes) {
    for (let i = 0; i < labels.length; i++) {
      const colorIndex = i % colorPalettes?.length;
      colorsPalettesArray.push(colorPalettes[colorIndex]);
    }
  }

  let colorsArray = []
  if (colors && Object.keys(colors).length > 0) {
    let maxIndex = 0;
    for (const key in colors) {
      const index = parseInt(key);
      if (!isNaN(index) && index > maxIndex) {
        maxIndex = index;
      }
    }
    // Create the array with empty strings
    colorsArray = Array.from({ length: maxIndex + 1 }, (_, index) => {
      const color = colors[index.toString()];
      return color || "";
    });
  }

  for (let i = 0; i < y.length; i++) {
    const column = y[i]
    let values = rows.map((row) => (row[column]))
    let groups = rows.map((row) => row[series])

    traces.push({
      values,
      labels,
      transforms: [{
        type: 'groupby',
        groups: groups,
      }],
      type: "pie",
      name: column,
      domain: {
        row: 0,
        column: i
      },
      textposition: "inside",
      direction: type,
      marker: {
        sizemode: sizemode,
        missingValues: missingValuesAsZero ? "0" : null,
        colors: colorPalettes && colorPalettes !== "default" ? colorsPalettesArray : colorsArray
      },
      hole: hole,
    })
  }

  const m = 60

  const layout = {
    automargin: true,
    margin: { pad: 10, t: m, b: m, l: m, r: m },
    showlegend: legend.enabled,
    legend: {
      tracorder: legend.tracorder,
      placement: legend.placement,
    },
    grid: { rows: 1, columns: traces.length },
  }
  return { data: traces, layout }
}

function getBarChartOptions({ options, visData }) {
  let { columnMapping, legend, seriesOptions, xAxis, yAxis, series: { stacking }, showDataLabels, numberFormat, orientation, colorPalettes
  } = options
  const { x, y, series } = getXAndYFromColumns(columnMapping)
  const { rows } = visData.data
  const traces = []
  const labels = rows.map((row) => row[x])
  const trimLabels = labels?.map(label => label?.length > 25 ? label?.slice(0, 24) + '...' : label)

  const colorsArray = []
  if (colorPalettes) {
    for (let i = 0; i < labels?.length; i++) {
      const colorIndex = i % colorPalettes.length;
      colorsArray.push(colorPalettes[colorIndex]);
    }
  }

  for (let i = 0; i < y.length; i++) {
    const column = y[i]
    let config = seriesOptions[column]
    let values = orientation === "h" ? rows.map((row) => (row[column])) : rows.map((row) => (row[column])).map(v => numeral(v).format(numberFormat))
    let groups = rows.map((row) => row[series])
    let yaxisId = config?.yAxis === 1 ? 'y2' : 'y1'


    traces.push({
      mode: 'lines+text',
      x: trimLabels,
      y: values,
      customdata: labels,
      name: config?.name || column,
      text: showDataLabels ? values : null,
      type: config?.type === 'column' ? 'bar' : config?.type || "bar",
      hovertemplate: orientation === "h" ? `(%{y}, %{customdata})` : `(%{customdata}, %{y:,})`,
      texttemplate: showDataLabels && config?.type === "column" ? `%{y:,}` : null,
      marker: {
        color: colorPalettes && colorPalettes !== "default" ? colorsArray : config?.color,
        zindex: config?.zIndex,
        missingValuesAsZero: true
      },
      transforms: series ? [{
        type: 'groupby',
        groups: groups,
      }] : null,
      yaxis: yaxisId,
      connectgaps: true,
      linkUrlTemplate: options?.linkUrlTemplate,
      linkOpenInNewTab: options?.linkOpenInNewTab,
      orientation
    })
  }

  const m = 60

  const layout = {
    automargin: true,
    margin: {
      l: m,
      r: m,
      t: m,
      b: m,
      pad: 10,
    },
    showlegend: legend.enabled,
    legend: {
      orientation: 'h',
      yanchor: 'top',
      // y: -0.2,
      traceorder: legend.traceorder,
      placement: legend.placement,
      font: {
        size: 10,
      },
    },
    xaxis: {
      title: orientation === "h" ? yAxis[0].title?.text : xAxis?.title?.text,
      standoff: 10,
      automargin: true,
      tickfont: {
        size: 10
      },
      titlefont: {
        size: 14
      },
      type: orientation === "h" ? yAxis[0]?.type : xAxis?.type,
    },
    yaxis: {
      title: orientation === "h" ? xAxis?.title?.text : yAxis[0].title?.text,
      type: orientation === "h" ? xAxis?.type : yAxis[0]?.type,
      automargin: true,
      tickfont: {
        size: 10
      },
      titlefont: {
        size: 14
      },
      range: [yAxis[0]?.rangeMin, yAxis[0]?.rangeMax],
    },
    yaxis2: {
      title: yAxis[1].title?.text,
      type: yAxis[0]?.type,
      automargin: true,
      tickfont: {
        size: 10
      },
      titlefont: {
        size: 14
      },
      range: [yAxis[1]?.rangeMin, yAxis[1]?.rangeMax],
      overlaying: 'y1',
      side: 'right',
      rangemode: 'tozero'
    },
    barmode: stacking ? 'stack' : null,
  }
  return { data: traces, layout: layout }
}

function getLineChartOptions({ options, visData }) {
  const { columnMapping, legend, seriesOptions, xAxis, yAxis, numberFormat, showDataLabels, colorPalettes } = options
  const { x, y, series } = getXAndYFromColumns(columnMapping)
  const { rows } = visData.data
  const traces = []
  const labels = rows.map((row) => row[x])
  const trimLabels = labels?.map(label => label?.length > 25 ? label?.slice(0, 23) + '...' : label)

  const colorsArray = []
  if (colorPalettes) {
    for (let i = 0; i < labels?.length; i++) {
      const colorIndex = i % colorPalettes.length;
      colorsArray.push(colorPalettes[colorIndex]);
    }
  }

  for (let i = 0; i < y.length; i++) {
    const column = y[i]
    let config = seriesOptions[column]
    let values = rows.map((row) => (row[column])).map(v => numeral(v).format(numberFormat))
    let groups = rows.map((row) => row[series])
    let yaxisId = config?.yAxis === 1 ? 'y2' : 'y1'

    traces.push({
      mode: options?.globalSeriesType === "scatter" ? "markers+text" : 'lines+text',
      x: trimLabels,
      y: values,
      customdata: labels,
      name: config?.name || column,
      text: showDataLabels ? values : null,
      textposition: 'top center',
      type: config?.type === 'column' ? 'bar' : config?.type || "scatter",
      fill: options?.globalSeriesType === 'area' || config?.type === 'area' ? 'tozeroy' : null,
      hovertemplate: `(%{customdata}, %{y:,})`,
      texttemplate: showDataLabels && config?.type === "column" ? `%{y:,}` : null,
      marker: {
        color: colorPalettes && colorPalettes !== "default" ? colorsArray : config?.color,
        zindex: config?.zIndex,
        missingValuesAsZero: true
      },
      transforms: series ? [{
        type: 'groupby',
        groups: groups,
      }] : null,
      yaxis: yaxisId,
      connectgaps: true,
      linkUrlTemplate: options?.linkUrlTemplate,
      linkOpenInNewTab: options?.linkOpenInNewTab
    })
  }

  const m = 60

  const layout = {
    colorway: colorPalettes && colorPalettes !== "default" ? colorsArray : [],
    automargin: true,
    margin: {
      l: m,
      r: m,
      t: m,
      b: m,
      pad: 10,
    },
    showlegend: legend.enabled,
    legend: {
      tracorder: legend.traceorder,
      placement: legend.placement,
    },
    xaxis: {
      title: xAxis?.title?.text,
      standoff: 10,
      automargin: true,
      tickfont: {
        size: 10
      },
      titlefont: {
        size: 14
      },
      type: xAxis?.type
    },
    yaxis: {
      title: yAxis[0].title?.text,
      type: yAxis[0]?.type,
      automargin: true,
      tickfont: {
        size: 10
      },
      titlefont: {
        size: 14
      },
      range: [yAxis[0]?.rangeMin, yAxis[0]?.rangeMax],
    },
    yaxis2: {
      title: yAxis[1].title?.text,
      type: yAxis[1]?.type,
      automargin: true,
      tickfont: {
        size: 10
      },
      titlefont: {
        size: 14
      },
      range: [yAxis[1]?.rangeMin, yAxis[1]?.rangeMax],
      overlaying: 'y1',
      side: 'right',
      rangemode: 'tozero'
    },
  }
  console.log(traces)
  return { data: traces, layout }
}

function getBubbleChartOptions({ options, visData }) {
  let { columnMapping, legend, seriesOptions, xAxis, yAxis, showDataLabels, missingValuesAsZero, sizemode, coefficient, colorPalettes } = options
  const { x, y, series, size } = getXAndYFromColumns(columnMapping)
  const { rows } = visData.data
  const traces = []
  const labels = rows.map((row) => row[x])
  const trimLabels = labels?.map(label => label?.length > 25 ? label?.slice(0, 23) + '...' : label)

  const colorsArray = []
  if (colorPalettes) {
    for (let i = 0; i < labels?.length; i++) {
      const colorIndex = i % colorPalettes.length;
      colorsArray.push(colorPalettes[colorIndex]);
    }
  }

  for (let column of y) {
    let config = seriesOptions[column]
    let values = rows.map((row) => row[column])
    let sizes = rows.map((row) => row[size] * coefficient)
    let groups = rows.map((row) => row[series])
    let yaxisId = config?.yAxis === 1 ? 'y2' : 'y1'

    traces.push({
      x: trimLabels,
      y: values,
      customdata: labels,
      name: config?.name || column,
      text: showDataLabels ? values : null,
      mode: 'markers',
      type: 'scatter',
      hovertemplate: `(%{customdata}, %{y:,})`,
      marker: {
        color: colorPalettes && colorPalettes !== "default" ? colorsArray : config?.color,
        size: sizes,
        sizemode: sizemode,
        missingValues: missingValuesAsZero ? "0" : null,
      },
      transforms: [{
        type: 'groupby',
        groups: groups,
      }],
      yaxis: yaxisId,
    })
  }

  const m = 60

  const layout = {
    automargin: true,
    margin: {
      l: m,
      r: m,
      t: m,
      b: m,
      pad: 4,
    },
    showlegend: legend.enabled,
    legend: {
      tracorder: legend.traceorder,
      placement: legend.placement,
    },
    xaxis: {
      title: xAxis?.title?.text,
      automargin: true,
      tickfont: {
        size: 10
      },
      titlefont: {
        size: 14
      },
      type: xAxis?.type,
      range: [series ? 0 : -1, coefficient < 1 || series ? undefined : labels.length],
    },
    yaxis: {
      title: yAxis[0].title?.text,
      type: yAxis[0]?.type,
      automargin: true,
      tickfont: {
        size: 10
      },
      titlefont: {
        size: 14
      },
      range: [yAxis[0]?.rangeMin || 0, yAxis[0]?.rangeMax],
    },
    yaxis2: {
      title: yAxis[1].title?.text,
      type: yAxis[1]?.type,
      automargin: true,
      tickfont: {
        size: 10
      },
      titlefont: {
        size: 14
      },
      range: [yAxis[1]?.rangeMin, yAxis[1]?.rangeMax],
      overlaying: 'y1',
      side: 'right',
      rangemode: 'tozero'
    },
  }
  return { data: traces, layout: layout }
}

function getWaterfallChartOptions({ options, visData }) {
  const { columnMapping, legend, orientation, measure, xAxis, yAxis, colorPalettes } = options;
  const { x, y, text } = getXAndYFromColumns(columnMapping);
  const { rows } = visData.data;
  const traces = [];
  const labels = rows.map((row) => row[x])
  const trimLabels = labels?.map(label => label?.length > 25 ? label?.slice(0, 23) + '...' : label)

  const colorsArray = []
  if (colorPalettes) {
    for (let i = 0; i < labels?.length; i++) {
      const colorIndex = i % colorPalettes.length;
      colorsArray.push(colorPalettes[colorIndex]);
    }
  }

  for (let i = 0; i < y.length; i++) {
    const column = y[i];
    const values = rows.map((row) => row[column]);

    traces.push({
      x: trimLabels,
      y: values,
      type: "waterfall",
      name: column,
      customdata: labels,
      orientation: orientation || "v",
      measure: measure,
      text: text,
      textposition: "outside",
      connector: {
        mode: "between",
        line: {
          width: 4,
          color: "rgb(0, 0, 0)",
          dash: 0
        }
      },
      marker: {
        color: colorPalettes && colorPalettes !== "default" ? colorsArray : [],
      },
    });
  }

  const m = 60;

  const layout = {
    automargin: true,
    margin: { pad: 10, t: m, b: m, l: m, r: m },
    showlegend: legend.enabled,
    legend: {
      tracorder: legend.tracorder,
      placement: legend.placement,
    },
    xaxis: {
      title: xAxis?.title?.text,
      automargin: true,
      tickfont: {
        size: 10
      },
      titlefont: {
        size: 14
      },
      type: xAxis?.type,
      range: [-0.1, labels.length],
    },
    yaxis: {
      title: yAxis[0].title?.text,
      type: yAxis[0]?.type,
      automargin: true,
      tickfont: {
        size: 10
      },
      titlefont: {
        size: 14
      },
      range: [yAxis[0]?.rangeMin || 0, yAxis[0]?.rangeMax],
    },
  }

  return { data: traces, layout };
}

function getTreemapChartOptions({ options, visData }) {
  const { columnMapping, legend } = options

  const { labels, parents, values } = convertDataToHierarchy(visData.data, columnMapping);
  // console.log(visData.data)
  // console.log(labels)
  // console.log(parents)
  // console.log(values)

  const data = [{
    type: "treemap",
    labels,
    parents,
    values,
    // textinfo: "label+value+percent parent+percent entry",
    outsidetextfont: { "size": 20, "color": "#377eb8" },
    marker: { "line": { "width": 2 } },
  }];

  const m = 60

  const layout = {
    automargin: true,
    margin: { pad: 10, t: m, b: m, l: m, r: m },
    showlegend: legend.enabled,
    legend: {
      tracorder: legend.tracorder,
      placement: legend.placement,
    },
  };

  return { data, layout };
}

function getFunnelChartOptions({ options, visData }) {
  const { columnMapping, legend, seriesOptions, xAxis, yAxis, showDataLabels, colorPalettes } = options
  const { x, y, series } = getXAndYFromColumns(columnMapping)
  const { rows } = visData.data
  const traces = []
  const labels = rows.map((row) => row[x])

  const colorsArray = []
  if (colorPalettes) {
    for (let i = 0; i < labels?.length; i++) {
      const colorIndex = i % colorPalettes.length;
      colorsArray.push(colorPalettes[colorIndex]);
    }
  }

  for (let i = 0; i < y.length; i++) {
    const column = y[i]
    let config = seriesOptions[column]
    let values = rows.map((row) => (row[column]))

    traces.push({
      x: labels,
      y: values,
      name: config?.name || column,
      text: showDataLabels ? values : null,
      type: config?.type || "funnel",
      // hoverinfo: 'x+percent previous+percent initial',
      // textinfo: "value+percent initial",
      hovertemplate: `(%{x:,}, %{y})`,
      textposition: "inside",
      marker: {
        color: colorPalettes && colorPalettes !== "default" ? colorsArray : config?.color,
      },
    })
  }

  const m = 60

  const layout = {
    automargin: true,
    margin: {
      l: m,
      r: m,
      t: m,
      b: m,
      pad: 10,
    },
    showlegend: legend.enabled,
    legend: {
      tracorder: legend.traceorder,
      placement: legend.placement,
    },
    xaxis: {
      title: xAxis?.title?.text,
      standoff: 10,
      automargin: true,
      tickfont: {
        size: 10
      },
      titlefont: {
        size: 14
      },
    },
    yaxis: {
      title: yAxis[0].title?.text,
      automargin: true,
      tickfont: {
        size: 10
      },
      titlefont: {
        size: 14
      },
    },
  }

  return { data: traces, layout }
}

function getXAndYFromColumns(columns) {
  if (!columns) {
    columns = { Column0: "x", Column1: "y" }
  }

  let x
  let series
  let y = []
  let size

  for (let column of Object.keys(columns)) {
    if (columns[column] === "x") {
      x = column
    } else if (columns[column] === "series") {
      series = column
    } else if (columns[column] === "y") {
      y.push(column)
    } else if (columns[column] === "size") {
      size = column
    }
  }

  return { x, y, series, size }
}

export function updateURLTable(url, text, record) {
  const baseURL = window.location.origin;
  const regex = /(\d+)(.*)/;
  const match = url?.match(regex);
  const desiredSubstring = match ? match[1] + match[2] : "";
  let URL = `${baseURL}/requests/${desiredSubstring}`;

  // Loop through the properties of the record object
  for (const [key, value] of Object.entries(record)) {
    // Replace underscores and spaces in the key with each other to match parameter name format
    const formattedKey = key.replace(/_/g, " ").replace(/ /g, "_");

    // If the value is an array, surround its values with single quotes before updating the URL
    const updatedValue = Array.isArray(value) ? value.map((v) => `'${v}'`).join(",") : value;

    // Replace the placeholder {{ formattedKey }} in the URL with the corresponding value from the record object
    URL = URL.replace(`{{ ${formattedKey} }}`, updatedValue);
  }

  // Replace any spaces in the stringToReplace value with %20
  text = text.replace(/ /g, "%20");

  // Replace the {{@}} placeholder with the stringToReplace value
  URL = URL.replace("{{ @ }}", text);
  return URL;
}

export function getWordCloudData({ data, options }) {
  const { rows } = data;
  const text = rows.map((row) => row[options.column]);
  let frequencies = [];
  if (options.frequenciesColumn === "") {
    frequencies = Array(rows.length).fill(1);
  } else {
    frequencies = rows.map((row) => parseInt(row[options.frequenciesColumn], 10));
  }

  // Combine text and frequencies into an array of objects, filtering out any undefined or null values
  const wordCloudData = text.reduce((acc, word, index) => {
    if (word !== undefined && word !== null) {
      const frequency = frequencies[index];
      if (!acc[word]) {
        acc[word] = frequency;
      } else {
        acc[word] += frequency;
      }
    }
    return acc;
  }, {});

  const wordCloudArray = Object.keys(wordCloudData).map((word) => ({
    text: word,
    value: wordCloudData[word],
  }));

  // Remove words with zero frequency
  const filteredData = wordCloudArray.filter((word) => word.value !== 0);

  // Apply word length and count limits
  const { wordLengthLimit, wordCountLimit } = options;
  const wordData = filteredData.filter((word) => {
    const minLength = wordLengthLimit?.min
    const maxLength = wordLengthLimit?.max
    const minCount = wordCountLimit?.min
    const maxCount = wordCountLimit?.max

    const wordLength = word.text.length;
    const wordCount = word.value;

    const isWithinLengthLimit =
      (minLength === null || wordLength >= minLength) &&
      (maxLength === null || wordLength <= maxLength);

    const isWithinCountLimit =
      (minCount === null || wordCount >= minCount) &&
      (maxCount === null || wordCount <= maxCount);

    return isWithinLengthLimit && isWithinCountLimit;
  });

  return wordData;

}

function convertDataToHierarchy(data, columnMapping) {
  const labels = [];
  const parents = [];
  const values = [];

  const { label, parent, uniqueValue, value } = getLabelAndParentsFromColumns(columnMapping)

  // Iterate through rows to build labels and parents arrays
  for (const row of data.rows) {
    const parentKey = row[parent];
    const labelKey = row[label];
    const uniqueID = row[uniqueValue];
    const valueKey = row[value];

    if (!labels.includes(labelKey)) {
      labels.push(labelKey);
      parents.push("");
    }

    labels.push(uniqueID);
    parents.push(labelKey);
    values.push(valueKey);
  }

  return { labels, parents, values };
}

function getLabelAndParentsFromColumns(columns) {
  if (!columns) {
    columns = { Column0: "label", Column1: "parent", Column2: "uniqueValue", Column3: "value" }
  }

  let label
  let parents
  let uniqueValue
  let value

  for (let column of Object.keys(columns)) {
    if (columns[column] === "label") {
      label = column
    } else if (columns[column] === "parent") {
      parents = column
    } else if (columns[column] === "uniqueValue") {
      uniqueValue = column
    } else if (columns[column] === "value") {
      value = column
    }
  }

  return { label, parents, uniqueValue, value }
}