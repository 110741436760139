import React from "react"
import useResizeObserver from "use-resize-observer"
import Plotly from "plotly.js-dist-min"

// Plotly has high memory usage
// we are using useDebounce to prevent drawing the chart
// every time the user resizes it, only after the user finishes resizing
function useDebounce(value, interval = 50) {
 const [state, setState] = React.useState(value)

 React.useEffect(() => {
  const timeout = setTimeout(() => setState(value), interval)

  return () => clearTimeout(timeout)
 }, [value])

 return state
}

export function updateURL(url, params, stringToReplace) {
 // const baseURL = window.location.origin;
 const regex = /(\d+)(.*)/;
 const match = url?.match(regex);
 const baseURL = url.split(match[0])[0]
 const desiredSubstring = match ? (match[1] + match[2]) : "invalidURL";
 let URL = `${baseURL}${desiredSubstring}`;
 for (const [key, value] of Object.entries(params)) {
  // Construct the regular expression to match the parameter name, handling spaces in the name
  // const regex = new RegExp(`(${encodeURIComponent(key.replace(/ /g, "_"))})=[^&#]*`, "i");
  const regex = new RegExp(`(${encodeURIComponent(key.replace(/ /g, "_"))})=(?!{{ @ }})[^&#]*`, "i");

  // If the value is an array, surround its values with single quotes before updating the URL
  const updatedValue = Array.isArray(value) ? value.map((v) => `'${v}'`).join(",") : value;

  // Replace the parameter value in the URL
  URL = URL.replace(regex, `$1=${encodeURIComponent(updatedValue)}`);
 }

 if (stringToReplace) {
  // Replace any spaces in the stringToReplace value with %20
  stringToReplace = typeof stringToReplace === 'string' ? stringToReplace?.replace(/ /g, "%20") : stringToReplace;

  // Replace the {{@}} placeholder with the stringToReplace value
  URL = URL.replace("{{ @ }}", stringToReplace);
 }

 return URL;
}

function VisChart({ data = [], layout, params, ...delegated }) {
 const { ref: chartContainer, width, height } = useResizeObserver()
 const chartElement = React.useRef()

 const value = React.useMemo(() => ({ width, height }), [width, height])

 const { width: w, height: h } = useDebounce(value, 20)

 React.useEffect(() => {
  const handlePlotlyClick = (eventData) => {
   const clickedInfo = eventData.points[0]
   const ExternalURL = clickedInfo?.data?.linkUrlTemplate
   const newTab = clickedInfo?.data?.linkOpenInNewTab
   const xValue = clickedInfo?.customdata
   if (ExternalURL) {
    window.open(updateURL(ExternalURL, params, xValue), newTab ? '_blank' : '');
   }
  }

  const element = chartElement.current // store the value in a separate variable

  Plotly.react(element, data, layout, { displaylogo: false })

  element.on('plotly_click', handlePlotlyClick)

  return () => {
   element.removeAllListeners('plotly_click')
  }
 }, [data, layout, params, chartElement])

 React.useEffect(() => {
  Plotly.relayout(chartElement.current, {
   ...layout,
   width: w,
   height: h,
   legend: {
    ...layout?.legend,
    orientation: w >= 500 ? layout?.legend?.placement === "below" ? 'h' : 'v' : data[0]?.type === "pie" ? "v" : 'h',
    y: w >= 500 ? layout?.legend?.placement === "below" ? -1 : null : data[0]?.type === "pie" ? -1 : 1.4,
    x: w >= 500 ? data?.some(item => item?.yaxis === 'y2') ? 1.15 : layout?.legend?.placement === "below" ? 0 : 1 : 0,
    bgcolor: 'transparent',
   },
  })

 }, [w, h, data, layout])

 return (
  <div ref={chartContainer} style={{ overflow: "hidden" }} {...delegated}>
   <div ref={chartElement} ></div>
  </div>
 )
}

export default VisChart